import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, PlaylistHeader, SEO, TypefaceList } from 'components';
import { getDarkerColor } from 'functions';
import styles from './playlistPage.module.scss';
import PlaylistIndex from '../../components/PlaylistIndex';

const PlaylistPage = ({ data, pageContext }) => {
  const playlist = data.allContentfulPlaylist.edges[0].node;

  const layoutRef = useRef(null);
  const themeColor = playlist.primaryColor;
  const darkerColor = getDarkerColor(themeColor);
  const spotColor = playlist.accentColor ? playlist.accentColor : darkerColor;

  useEffect(() => {
    const layoutElement = layoutRef.current;

    layoutElement.style.setProperty('--theme-color', themeColor);
    layoutElement.style.setProperty('--spot-color', spotColor);
    layoutElement.style.setProperty('--darker-color', darkerColor);
  });

  return (
    <Layout ref={layoutRef}>
      <SEO title={playlist.title} />
      <div className={styles.playlist}>
        <PlaylistHeader content={playlist} />
        <TypefaceList content={playlist} />
      </div>
      <footer className={styles.footer}>
        <PlaylistIndex allPlaylists={pageContext.allPlaylists} />
      </footer>
    </Layout>
  );
};

PlaylistPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default PlaylistPage;

export const query = graphql`
  fragment Typeface on ContentfulTypeface {
    description {
      json
    }
    designers {
      name
      website
    }
    downloadLink
    foundry {
      name
      website
    }
    name
    publishYear
    style
    pangramImage {
      file {
        url
      }
    }
  }

  fragment Playlist on ContentfulPlaylist {
    accentColor
    author {
      name
      website
    }
    description {
      json
    }
    primaryColor
    slug
    tags
    title
    typefaces {
      ...Typeface
    }
  }

  query getSinglePlaylist($slug: String!) {
    allContentfulPlaylist(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          ...Playlist
        }
      }
    }
  }
`;
